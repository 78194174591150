import React from "react"
import styles from "./Footer.module.css"
import { TiSocialInstagram } from "react-icons/ti"

export default function Footer({ instagram, title, telephone, address }) {
  return (
    <footer className={styles.footer}>
      <p className={styles.copy}>{[title, address, telephone].join(" - ")}</p>
      <div className={styles.socialLinks}>
        <a className={styles.socialLinksItem} href={instagram}>
          <TiSocialInstagram size="26px" />
        </a>
      </div>
    </footer>
  )
}
