import React from "react"
import styles from "./Header.module.css"
import { Link } from "gatsby"
import imageLogo from "./images/logo.png"

export default function Header({ siteTitle }) {
  const logoComponent = (
    <Link to="/">
      <img className={styles.logo} alt={siteTitle} src={imageLogo} />
    </Link>
  )

  return (
    <header className={styles.header}>
      <h1>{logoComponent}</h1>
      <nav>
        <ul>
          {[
            { label: "Projetos", href: "/projetos" },
            { label: "Escritório", href: "/escritorio" },
          ].map(({ label, href }, i) => (
            <li key={i} className={styles.navItem}>
              <Link
                className={styles.navItemAnchor}
                partiallyActive
                activeClassName={styles.active}
                to={href}
              >
                {label}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  )
}
