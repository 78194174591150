import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"

function SEO({ title, description, faviconMetaTags, meta }) {
  return (
    <Helmet
      htmlAttributes={{
        lang: "pt-br",
      }}
      title={title}
      link={(faviconMetaTags || [])
        .filter(o => o.tagName === "link")
        .map(o => o.attributes)}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ]
        .concat(meta)
        .concat(
          (faviconMetaTags || [])
            .filter(o => o.tagName === "meta")
            .map(o => o.attributes)
        )}
    />
  )
}

SEO.defaultProps = {
  meta: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
