import React from "react"
import GatsbyImage from "gatsby-image"
import GatsbyBackgroundImage from "gatsby-background-image"

let _isSafari

const Image = React.memo(function Image(_props) {
  const [props, setProps] = React.useState(_props)

  React.useEffect(() => {
    if (_isSafari === undefined) {
      _isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    }

    if (!_isSafari) return

    const propName = props.fixed ? "fixed" : props.fluid ? "fluid" : null
    if (!propName) return

    setProps(props => ({
      ...props,
      [propName]: {
        ...props[propName],
        src: props[propName].src.replace(/webp/g, "png"),
        srcSet: props[propName].srcSet.replace(/webp/g, "png"),
        base64: props[propName].src,
      },
    }))
  }, [props.fixed, props.fluid])

  return props.background ? (
    <GatsbyBackgroundImage {...props} />
  ) : (
    <GatsbyImage {...props} />
  )
})

export default Image
