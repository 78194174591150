import React from "react"
import PropTypes from "prop-types"
import styles from "./Carousel.module.css"
import { Fade } from "react-slideshow-image"
import Image from "../Image/Image"
import { Link } from "gatsby"

export default function Carousel({ items }) {
  return (
    <div className={styles.container}>
      <div className={styles.slideShowContainer}>
        <Fade infinite indicators={false} arrows={false}>
          {items.map(({ url, image }, i) =>
            url ? (
              <Link key={i} to={url} className={styles.imageContainer}>
                <Image background className={styles.image} {...image} />
              </Link>
            ) : (
              <div key={i} className={styles.imageContainer}>
                <Image background className={styles.image} {...image} />
              </div>
            )
          )}
        </Fade>
      </div>
    </div>
  )
}

Carousel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      image: PropTypes.shape({ fluid: PropTypes.object.isRequired }).isRequired,
    })
  ).isRequired,
}
