import React from "react"
import styles from "./Text.module.css"
import cx from "classnames"

export default function Text({
  children,
  html,
  inline,
  uppercase,
  bold,
  semispread,
  spread,
}) {
  const className = cx(styles.text, {
    [styles.uppercase]: uppercase,
    [styles.bold]: bold,
    [styles.spread]: spread,
    [styles.semispread]: semispread,
  })

  const tagName = inline ? "span" : "p"

  return html
    ? React.createElement(tagName, {
        className,
        dangerouslySetInnerHTML: { __html: html },
      })
    : React.createElement(tagName, { className }, children)
}
