import React from "react"
import styles from "./Spacer.module.css"
import cx from "classnames"

export default function Spacer({ small, large }) {
  const className = cx(styles.spacer, {
    [styles.small]: small,
    [styles.large]: large,
  })

  return <div className={className} />
}
