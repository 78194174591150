/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import styles from "./Layout.module.css"
import "./Layout.globalStyles.css"

import SEO from "../../components/Seo/Seo"
import Header from "../../components/Header/Header"
import Footer from "../../components/Footer/Footer"

const Layout = ({ children, seoTitle, seoDescription, footerDivider }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site: datoCmsSite {
          faviconMetaTags {
            tags
          }
          seo: globalSeo {
            siteName
            titleSuffix
            fallbackSeo {
              description
              title
            }
          }
        }
        footer: datoCmsAbout {
          instagram
          title
          address
          telephone
        }
      }
    `
  )

  return (
    <>
      <SEO
        title={
          seoTitle
            ? `${seoTitle}${data.site.seo.titleSuffix}`
            : data.site.seo.fallbackSeo.title
        }
        description={seoDescription || data.site.seo.fallbackSeo.description}
        faviconMetaTags={data.site.faviconMetaTags.tags}
      />
      <div className={styles.container}>
        <Header siteTitle={data.site.seo.siteName} />
        <main className={styles.main}>{children}</main>
        {footerDivider && <div className={styles.footerDivider} />}
        <Footer {...data.footer} />
      </div>
    </>
  )
}

Layout.propTypes = {
  seoTitle: PropTypes.string,
  seoDescription: PropTypes.string,
  footerDivider: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Layout
