import React from "react"
import styles from "./Title.module.css"
import cx from "classnames"

export default function Title({ children, tagName = "h2", light, inline }) {
  const className = cx(styles.title, {
    [styles.light]: light,
    [styles.inline]: inline,
  })

  return React.createElement(tagName, { className }, children)
}
