function slugify(string) {
  return string.toLowerCase().replace(" ", "-")
}

exports.slugify = slugify

exports.projectPath = function projectPath(code, slug) {
  return `/projetos/${slugify(code)}/${slug}`
}

exports.tagPath = function tagPath(slug) {
  return `/projetos/categoria/${slug}`
}
